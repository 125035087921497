.gBlock {
  width: 100%;
  min-height: 100vh;
}

.gBlock .container {
  background-color: #FFFFFF;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.gBlock .container .left {
  width: 45%;

}

.gBlock .container .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.gBlock .container .right .field-container {
  padding: 10px 10px;
}

.field-container input {
  padding: 5px 0 5px 10px;
  line-height: 1.5;
  width: 300px;
}