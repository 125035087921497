.input-container {
  position: relative;
  width: 100%;
  height: 50px;
  border: 4px solid #979494;
  border-radius: 10px;
  padding: 2px;
  margin-bottom: 10px;

  &.error {
    margin-bottom: 0;
  }

  & svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

input.form-input {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #979494;
  padding: 7px 0 7px 14px;
}

.cut {
  height: 20px;
  position: absolute;
  left: 18px;
  top: -21px;
  opacity: 0;
  transition: transform 200ms, opacity 200ms;
  background-color: #FFFFFF;
  font-size: 13px;
  line-height: 1;
  color: transparent;
  padding: 1px 5px;
}

.form-input:focus ~ .cut,
.form-input:not(:placeholder-shown) ~ .cut {
  transform: translateY(9px);
  opacity: 1;
}

.placeholder {
  line-height: 14px;
  color: #979494;
  pointer-events: none;
  position: absolute;
  top: 14px;
  left: 20px;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms, opacity 200ms;
}

.form-input:focus ~ .placeholder,
.form-input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-23px) translateX(6px) scale(0.75);
}

.error-alert {
  padding: 0 6px 6px;
  color: tomato;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 3px;
}